import React, { useRef, useContext, useState } from 'react';
import Slider from 'react-slick';

import { RoutesContext } from 'context/RoutesContext';
import CustomLink from 'components/utils/CustomLink';
import EmbedVideoPlayer from 'components/utils/EmbedVideoPlayer';
import Modal from 'components/utils/Modal';
import useNewsBanner from 'data/queries/useNewsBanner';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import { LangContext } from 'context/LangContext';
import * as style from 'styles/components/homepage/newsBannerNoThumbs.module.scss';

import defaultImage from '../../images/default-image.jpg';

const SlickArrow = (props)  => {
    const { className, onClick } = props;
    return(
        <button type="button" onClick={onClick} className={`arrow ${className}`} >
            <i className="ico-chevron" aria-hidden="true" />
            <span className="visually-hidden">Flubar</span>
        </button>
      )
}

const NewsBanner = ({ isHomepage }) => {
    const { lang } = useContext(LangContext);
    const { routes } = useContext(RoutesContext) || { routes: [] };

    const news = useNewsBanner().filter((items) => items.language.hreflang === lang.hreflang);

    let newsListRoute = '';
    if (routes && routes.length !== 0) {
        newsListRoute = routes.find(
            (r) => r.node.name === 'newsList' && r.node.lang === lang.hreflang,
        ).node.path;
    }

    const sliderRef = useRef();

    const [activeSlide, setActiveSlide] = useState(0);
    const [videoPlaying, setVideoPlaying] = useState(false);

    const sliderAfterChange = (current) => {
        // setActiveSlide(current === 0 ? news.length - 1 : current - 1);
        setActiveSlide(current);
    };

    const sliderSettings = {
        dots: true,
        fade: true,
        arrows: true,
        infinite: true,
        speed: 400,
        autoplaySpeed: 8000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        swipe: true,
        draggable: false,
        autoplay: false,
        nextArrow: <SlickArrow to="next" />,
        prevArrow: <SlickArrow to="prev" />,
        afterChange: (current) => sliderAfterChange(current),
        onInit: () =>
            setTimeout(() => {
                // setAnimate(true);
            }, 1000),
    };

    const buildNewsVisual = (newsItem, className = '') => {
        const defaultVisual = {
            asset: {
                url: defaultImage,
            },
        }
        const visual = newsItem.videoPreviewImage || (newsItem.image && newsItem.image.defaultImage && newsItem.image.defaultImage.image) || defaultVisual;

        const visualPosition = {
            objectPosition: visual.hotspot
                ? `${visual.hotspot.x * 100}% ${visual.hotspot.y * 100}%`
                : '50% 50%',
        };

        return (
            <img
                className={className}
                src={`${visual.asset.url}?w=2000`}
                alt={newsItem.title}
                style={visualPosition}
            />
        );
    };

    return (
        <div className={`${style.slider} ${isHomepage ? 'homepage' : ''}`}>
            {news && news.length && (
                <div className={style.slider__main}>
                    <Slider {...sliderSettings} ref={sliderRef}>
                        {news.map((item, index) => (
                            <div key={item.id} className={style.slide}>
                                <div className={style.slide__mainContent}>
                                    <span className={style.slide__number}>
                                        0{index + 1}
                                    </span>

                                    {item.video &&
                                        <>
                                            <h1
                                                className={`title-lg title-sm-mobile text-bold ${style.slide__mainTitle}`}
                                            >
                                                {item.title}
                                            </h1>
                                            <button type="button" className="cta-white-sec" onClick={() => setVideoPlaying(true)} aria-label={getFormattedMessage('global.watchClip', lang)}>
                                                <FormattedMessage id="global.watchClip" />
                                            </button>
                                        </>
                                    }

                                    {!item.video &&
                                        <>
                                            <CustomLink to={`/${newsListRoute}/${item.slug.current}`}>
                                            <h1
                                                className={`title-lg title-sm-mobile text-bold ${style.slide__mainTitle}`}
                                            >
                                                {item.title}
                                            </h1>
                                            </CustomLink>
                                            <CustomLink to={`/${newsListRoute}/${item.slug.current}`} className="cta-white-sec">
                                            <FormattedMessage id="global.readNews" />
                                            </CustomLink>
                                        </>
                                    }
                                </div>
                                {item.video &&
                                    <div onClick={() => setVideoPlaying(true)} onKeyDown={() => setVideoPlaying(true)} role="presentation">
                                        <div className={style.slide__background} />
                                    </div>
                                }
                                {!item.video &&
                                    <CustomLink to={`/${newsListRoute}/${item.slug.current}`}>
                                        <div className={style.slide__background} />
                                    </CustomLink>
                                }

                                <div className={style.slide__image}>
                                    {buildNewsVisual(item)}
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            )}

            {videoPlaying && (
                <Modal
                    className={style.slider__videoModal}
                    show={videoPlaying}
                    onClose={() => setVideoPlaying(false)}
                >
                <EmbedVideoPlayer
                    url={`${news[activeSlide].video.teaserVideo.url}?autoplay=1`}
                    title={news[activeSlide].title}
                />
                </Modal>
            )}
        </div>
    );
};

export default NewsBanner;
