// extracted by mini-css-extract-plugin
export var slide = "newsBannerNoThumbs-module--slide--lhUvB";
export var slide__background = "newsBannerNoThumbs-module--slide__background--7bh7-";
export var slide__cat = "newsBannerNoThumbs-module--slide__cat--6Alyu";
export var slide__image = "newsBannerNoThumbs-module--slide__image--SZTqU";
export var slide__mainContent = "newsBannerNoThumbs-module--slide__mainContent--SPjeH";
export var slide__mainTitle = "newsBannerNoThumbs-module--slide__mainTitle--wUk7k";
export var slide__number = "newsBannerNoThumbs-module--slide__number--TA7AP";
export var slider = "newsBannerNoThumbs-module--slider--IiQ7V";
export var slider__main = "newsBannerNoThumbs-module--slider__main--I5O2H";
export var slider__videoModal = "newsBannerNoThumbs-module--slider__videoModal--9bRkU";