import * as React from 'react';

import * as style from 'styles/components/news/blockListHomepageCategorized.module.scss';
import CustomLink from 'components/utils/CustomLink';
import FormattedMessage from 'components/utils/FormattedMessage';

import NewsItem from './Item';

const NewsBlockListHomepage = ({ title, items }) => (
    <div className={`${style.newsBlockListHP} wrapper block-paddings`}>
        <h2 className="title-sm title-xs-mobile text-semibold">{title}</h2>
        <ul>
            {items.map((item, index) => (
                <li key={index}>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {index === 0 ? (
                        <NewsItem item={item} isHighlighted />
                    ) : index === 2  || index === 1 ? (
                        <NewsItem item={item} customWidth={420} customHeight={448} />
                    ) : (
                        <NewsItem item={item} />
                    )}
                </li>
            ))}
        </ul>
        <div className="center">
            <CustomLink className="cta-white-sec" namedRoute="newsList">
                <FormattedMessage id="news.seeMoreNews" />
            </CustomLink>
        </div>
    </div>
);

export default NewsBlockListHomepage;
