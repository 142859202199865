import * as React from 'react';

import { useAllMerchItems } from 'data/queries/useAllMerch';
import Slider from 'components/utils/Slider';
import MerchItem from 'components/merch/Item';
import FormattedMessage from 'components/utils/FormattedMessage';

const MerchBlockList = () => {
    const merchItems = useAllMerchItems();
    if (merchItems.length === 0) {
        return null;
    }

    return (
        <div className="block-paddings">
            <Slider
                title={<FormattedMessage id="merch.apparel" />}
                slidesDesktop={3}
                slidesTablet={2}
                slidesMob={1}
                linkText={<FormattedMessage id="merch.shopLink" />}
                linkNamedRoute="shop"
            >
                {merchItems.map((item, index) => (
                    <MerchItem key={index} item={item} isSecondary />
                ))}
            </Slider>
        </div>
    );
};

export default MerchBlockList;
