import { useStaticQuery, graphql } from 'gatsby';

const useSponsorAdsBanner = () => {
    const {
        allSanityConfigGlobal: {
            nodes: [{ homepageAdsBanner: sponsorAds }],
        },
    } = useStaticQuery(graphql`
        query sponsorAdsBanner {
            allSanityConfigGlobal {
                nodes {
                    homepageAdsBanner {
                        alt
                        external
                        image {
                            hotspot {
                                x
                                y
                            }
                            asset {
                                url
                                id
                            }
                        }
                        url
                        showAdMark
                    }
                }
            }
        }
    `);

    return sponsorAds;
};

export default useSponsorAdsBanner;
