import { useStaticQuery, graphql } from 'gatsby';

const useMatchSplash = () => {
    const { sanityConfigSplash: matchSplash } = useStaticQuery(graphql`
        query {
            sanityConfigSplash {
                matchVisual {
                    asset {
                        url
                    }
                }
                matchVisualWomen {
                    asset {
                        url
                    }
                }
                matchSponsor {
                    logoWhite {
                        ...ImageWithPreview
                    }
                    title
                    url
                }
            }
        }
    `);

    return matchSplash;
};

export default useMatchSplash;
