import { useStaticQuery, graphql } from 'gatsby';

const useSponsorAdsBannerMobile = () => {
    const {
        allSanityConfigGlobal: {
            nodes: [{ homepageAdsBannerMobile: sponsorAdsMobile }],
        },
    } = useStaticQuery(graphql`
        query sponsorAdsBannerMobile {
            allSanityConfigGlobal {
                nodes {
                    homepageAdsBannerMobile {
                        alt
                        external
                        image {
                            hotspot {
                                x
                                y
                            }
                            asset {
                                url
                                id
                            }
                        }
                        url
                        showAdMark
                    }
                }
            }
        }
    `);

    return sponsorAdsMobile;
};

export default useSponsorAdsBannerMobile;
