import React, { useContext, useEffect, useState } from 'react';

import * as style from 'styles/components/homepage/matches.module.scss';
import Slider from 'components/utils/Slider';
import Calendar from 'components/match/Calendar';
import MatchItem from 'components/match/Item';
import { breakpoints } from 'data/utils';
import enMessages from 'i18n/en.json';
import seMessages from 'i18n/se.json';
import { LangContext } from 'context/LangContext';

const MESSAGES = {
    en: enMessages,
    se: seMessages,
};

const sliderResponsiveParams = [
    {
        breakpoint: breakpoints.large,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
        },
    },
    {
        breakpoint: breakpoints.large - 200,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
    {
        breakpoint: breakpoints.medium,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
        },
    },
    {
        breakpoint: breakpoints.small + 100,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },
];

const MatchesHomepage = ({ allMatches, filteredTeam }) => {
    const { lang } = useContext(LangContext);

    const [isMobile, setIsMobile] = useState(
        typeof window !== 'undefined' && window.innerWidth < breakpoints.medium,
    );

    useEffect(() => {
        const setMobile = () => {
            if (typeof window !== 'undefined') {
                setIsMobile(window.innerWidth < breakpoints.medium);
            }
        };

        window.addEventListener('resize', setMobile);

        return () => {
            window.removeEventListener('resize', setMobile);
        };
    }, []);

    const [selectedMatches, setSelectedMatches] = useState([]);

    // select the upcoming matches for a team (all teams if no team filter). Limited to 18 matches
    useEffect(() => {
        const now = new Date();
        let upcomingMatchesToDisplay = allMatches.filter((match) => new Date(match.startEventDate) >= now);

        if(filteredTeam === null) {
            upcomingMatchesToDisplay = upcomingMatchesToDisplay.filter((match) => match.team.title[lang.translationKey] === 'Herr' || match.team.title[lang.translationKey] === 'Dam' || match.teamExternal.title[lang.translationKey] === 'Herr' || match.teamExternal.title[lang.translationKey] === 'Dam');
        }

        if (filteredTeam !== null) {
            upcomingMatchesToDisplay = upcomingMatchesToDisplay.filter((match) => match.team.id === filteredTeam || match.teamExternal.id === filteredTeam);
        }

        if (upcomingMatchesToDisplay.length > 18) {
            upcomingMatchesToDisplay.length = 18;
        }

        setSelectedMatches(upcomingMatchesToDisplay);
    }, [filteredTeam, allMatches, lang]);

    return (
        <div className={`${style.matches} ${isMobile ? '' : 'wrapper'}`}>
            <div className={style.matches__slider}>
                <Slider
                    title={MESSAGES[lang.translationKey].match.matches}
                    linkNamedRoute="matchList"
                    linkText={MESSAGES[lang.translationKey].match.seeAll}
                    slidesDesktop={3}
                    customResponsive={sliderResponsiveParams}
                    hasWrapper={isMobile}
                    hasOverflow={!isMobile}
                    needRefresh
                >
                    {selectedMatches.length !== 0 && selectedMatches.map((item, index) => (
                        <MatchItem key={index} match={item} />
                    ))}
                </Slider>
            </div>
            {!isMobile && (
                <div className={`${style.matches__calendar}`}>
                    <Calendar filteredTeam={filteredTeam} matches={allMatches} isFullHeight />
                </div>
            )}
        </div>
    );
};

export default MatchesHomepage;
